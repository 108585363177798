import React from "react";
import Panel from "../components/templates/panel";
import Content from "../components/templates/content";
import Purpose from "../components/templates/proposal";
import Ribbon from "../components/templates/ribbon";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Login from "../components/login";

const ArticlePage = () => (
  <Layout>
    <Login />
    <SEO title="Article" />
    <Panel imgLink={"/images/article_banner.jpg"}>
      <div className="purpose mobile-hide">
        <h1>An article from the future</h1>
      </div>
    </Panel>
    <div className="mobile-show desktop-hide">
      <Ribbon bgColor="black">
        <h1>An article from the future</h1>
      </Ribbon>
    </div>
    <Content>
      <div className="container">
        <h2>
          Downtown West:<br></br> where all the city’s a stage
        </h2>
        <div className="breaker-thin"></div>
        <p className="compress-lg">
          There is a long-held almost-universal truth in development: that big
          usually means bad. You can picture it now: the overblown claims
          followed some years later by the unveiling of shiny but shallow
          results. Grand projects that replace the rich textures of urban life
          with vast bland monocultural pieces of city. In the last two decades,
          the Bay Area has seen its own special version — generic-brand hipster
          developments aimed at tech campus workers seeking a bed and the best
          java. Many would argue this is market forces at work. But you could
          also argue — is that what we asked for? Is this what people really
          want? Is development’s blandification inevitable? You could ask: and
          Google did.
          <br></br>
          <br></br>
          “We feel a great responsibility to get Downtown West right. San Jose
          is our home” says Alexa Arenas, Google’s director of real estate
          development, “We knew it couldn’t feel like an experiment: but we also
          didn’t want to repeat the Bay’s missed opportunities.” Alongside
          Lendlease, the result was a journey: a series of small acts that belie
          these two corporate behemoths. They moved into downtown, they put away
          the masterplan, and they listened. “The key moment came when we
          realised all of us — the city, the small businesses, Googlers — were
          missing the same thing: a stage to be ourselves”
        </p>
        <br></br>
        <br></br>
        <br></br>
        <h2 className="compress-lg">
          Rethinking the downtown as a stage: it’s a poetic idea but why here?
          And what does that look like?
        </h2>
        <br></br>
        <br></br>
        <br></br>
        <p className="compress-lg">
          San Jose is the second largest city in California, and tenth largest
          in the country. Yet despite its wonderful history — from Spanish
          settlement to mercury mining, fruit canning to becoming the epicentre
          of western technological advancement — it failed to capture the
          collective imagination because there was no place to go to really
          understand it. Brilliantly diverse, its neighborhoods of Little
          Saigon, Calle Willow, Japantown were like the best ingredients, just
          without the melting pot. San Jose was a city of echo chambers: people
          trapped in their own bubbles of experience unable to bump into and
          understand each other without great personal efforts. A city bulging
          at the seams with people with big ideas, but who spent hours in cars
          or cloistered in tech campuses or shopping malls. “We saw the chance
          for a downtown where you experienced, tasted, listened, learned. That
          was really of and for the city. We wanted to create the stage: so that
          San Jose could be the performance”
        </p>
        <br></br>
        <br></br>
        <br></br>
        <h2 className="compress-lg">
          We wanted to create the stage: so that San Jose could be the
          performance”
        </h2>
        <br></br>
        <br></br>
        <br></br>
        <p className="compress-lg">
          Walk Downtown West today, and for all the talk of incremental changes
          and experimental approaches, you’d imagine a mess of half-finished
          projects and half-hearted nods to community. But it isn’t. There is a
          sense of care and pride without the zealous control freakery of
          manicured suburban lawns. A gentle loose dare-we-say Californian-ness
          to its informal but substantial community gardens and
          inner-child-inspiring street fountains. There are odd in-between
          spaces too. A neveria selling aguas frescas and paletas is squeezed
          between a trendy coworking space and the freeway. The size of a small
          garage, it’s one of a collection of spaces open to local retailers and
          leased on a percentage of profits basis — a hugely successful
          initiative aimed at ensuring small business has a long term future as
          part of San Jose’s downtown experience. The initiative is also an
          online community where its dog groomers and sneaker sellers also trade
          tips and advice.
        </p>
        <br></br>
        <br></br>
        <br></br>
        <h2 className="compress-lg">
          “It has meant a lot to us to be a part of the change. My family is
          excited again about the future of our business and our city. It’s
          brought back the smile to San Jose.”
          <br></br>
          <br></br>— Angel Garcia Sanchez, neveria owner
        </h2>
        <br></br>
        <br></br>
        <br></br>
        <p className="compress-lg">
          One of the biggest beneficiaries of Downtown West has been a permanent
          home to San Jose Made — those champions of maker culture and the
          instigators behind one of the city’s most eye-catching new landmarks,
          Mademart. This striking building’s ground floor is half workshop half
          pop-up shop — a noisy friendly mash-up of making and selling to the
          smell of bubbling pho and cafe sua da, while its roof has been
          transformed into one of California’s best examples of urban farming
          with fields of peppers glistening in the sun to the rumble of Highway
          87.<br></br>
          <br></br>The introduction of greenery is perhaps one of the most
          fundamental changes to the ex-industrial post-core area around Diridon
          station. Take for example the section of the Guadalupe river walk at
          Downtown West, which has become an integral centre of civic life here.
          “The challenge was seeing if a river could become a useful meaningful
          public space” says chief gardener Beth Nguyen. The result is a
          5-mile-long boardwalk following the river as it snakes through the
          city, and occasionally filling out to encompass small stages or play
          areas surrounded by palms. But look closer and you’ll spot that every
          incidental space is numbered — they are part of a network of
          programmable performance and educational spaces that San Joseans can
          log in and book for every scale of ambition and taste, from simple
          kids’ birthday parties to venues large enough to accommodate every
          member of SJSU’s Spartan Marching Band.
        </p>
        <br></br>
        <br></br>
        <br></br>
        <h2 className="compress-lg">
          “A network of programmable performance and educational spaces that San
          Joseans can log in and book”
        </h2>
        <br></br>
        <br></br>
        <br></br>
        <p className="compress-lg">
          “The response at the start was pretty tentative” Lily Wong admits as
          head cultural programmer, “it was a new idea and there was some
          skepticism. We also quickly realized we need to promote the
          opportunity through print and word of mouth not just online. But the
          real turning point was its use during the San Jose Jazz Summer Fest
          takeover. People really got its potential. Since then, we’ve been
          running at 80% capacity every weekend.”<br></br>
          <br></br> Wong’s admission isn’t the only misstep the team behind
          Downtown West are eager to share. Every conversation is a chapter in a
          tale of unfortunate events. Expensive learnings from constructing with
          cross-laminated timber. Backlashes around the radical reduction in car
          parking. Fiery moments in community engagement, although they’re quick
          to correct the term: “We didn’t engage, we didn’t consult: we cooked!”
          As a further demonstration of their unorthodox approach, Google and
          Lendlease have hosted bimonthly cook-outs where anyone from the
          community is welcome to come eat and discuss progress on-site. “People
          are more inclined to listen and consider things over food.”
          <br></br>
          <br></br>“Mistakes are part of how we learn” CEO Sundar Pichai puts
          simply, “and it’s ultimately how we’ve been able to balance commercial
          value and community value.” A clear part of Google’s strategy was to
          actively avoid creating another ‘campus’ — not only because of an
          ideological shift in the business but because of the commerciality.
          “We wanted to work alongside other inspirational businesses and we
          also wanted to contribute to the kind of downtown San Jose and
          Googlers deserve.”
        </p>
        <br></br>
        <br></br>
        <br></br>
        <h2 className="compress-lg">
          “We wanted to work alongside other inspirational businesses”
        </h2>
        <br></br>
        <br></br>
        <br></br>
        <p className="compress-lg">
          A key part of the overall strategy has been creating a range of
          highly-adaptable workspaces of varying sizes and finish, from HQ
          buildings to coworking, alongside retail spaces, small-scale cultural
          venues, and homes — mixing both market value and the affordable homes
          desperately needed in the city. But it doesn’t end there: Downtown
          West also includes its own daycare, school, health center, nursing
          home, and even a funeral home: “Yes, that certainly got some press!
          But that was about consciously breaking away from our everyday
          twenty-to-forty-something perspective. I mean, how are we all as
          businesses supposed to design responsibly for everyone from babies to
          the elderly without any connection to all stages of the human
          experience?”<br></br>
          <br></br> It’s an interesting question for the future of all
          development, and all places. But today, in San Jose, it’s just one of
          the many questions Downtown West implores visitors to consider.
          Because this is a place that’s less about the answers and more about
          the questions. Questions that get us talking, thinking, reconsidering,
          imagining. Important questions like — why isn’t this what we asked
          for? Why don’t we ask what people really want more often? Does this
          mean development’s blandification is something of the past?
        </p>
      </div>
    </Content>

    <Purpose
      fwd={true}
      bck={true}
      linkBack={"vision"}
      titleBack={"Downtown West"}
      titleTo={"Case Studies"}
      linkTo={"studies"}
    />
  </Layout>
);

export default ArticlePage;
